<template>
  <Form :labelWidth="170" :data="formData">
    <FormItem label="名称">
      <Input v-model="formData.name" placeholder="名称" :disabled="disabled" />
    </FormItem>
    <FormItem label="图标">
      <ImageUploader
        :defaultImages="currentImages"
        :width="250"
        :height="250"
        defaultCrop=""
        @uploadSucceed="imageUploaded"
        @removedUploaded="imageRemoved"
      />
    </FormItem>
    <FormItem label="父级">
      <Select v-model="formData.parentAlias" :disabled="disabled">
        <Option v-for="item in filteredList" :value="item.alias" :key="item.alias">
          {{ item.name }}
        </Option>
      </Select>
    </FormItem>
    <FormItem label="排序优先级">
      <Input v-model="formData.priority" placeholder="越大越靠前" />
    </FormItem>
    <FormItem>
      <Tag v-for="(user, idx) in selectedUsers" :key="idx" :name="idx" closable @on-close="removeUser(user.id)">
        {{ user.name }}
      </Tag>
    </FormItem>
    <FormItem v-if="withOperation">
      <Button @click="doUpdate" type="success" :disabled="disabled">
        修改
      </Button>
      <Button @click="doReset" :disabled="disabled">
        重置
      </Button>
      <Poptip confirm title="确认要删除这个分类吗?" @on-ok="doDelete">
        <Button type="warning" :disabled="disabled">
          删除
        </Button>
      </Poptip>
    </FormItem>
    <Modal v-model="showCategorySelector" width="400px" title="将这个商品中的分类移动到这个分类下">
      <BusinesstypeSelector @categorySelected="categorySelected" v-if="showCategorySelector" />
      <div slot="footer">
        <Button @click="showCategorySelector = false">取消</Button>
      </div>
    </Modal>
  </Form>
</template>

<script>
import { updateBusinesstype, deleteBusinesstype, queryBusinessTypeIcon } from "@/api"
import { apiUtil } from "@/util"

import ImageUploader from "@/components/ImageUploader.vue"
import BusinesstypeSelector from "@/components/businesstype/BusinesstypeSelector"

const initData = () => {
  return {
    name: "",
    alias: "",
    icon: "",
    parentAlias: "",
    userIds: [],
    priority: 0,
    relatedMediaId: null,
    relatedMediaPath: "",
    relatedMediaSuffix: ""
  }
}

export default {
  name: "CategoryEditor",
  components: { BusinesstypeSelector, ImageUploader },
  props: {
    cate: {
      type: Object,
      default: () => initData(),
    },
    parentList: {
      type: Array,
      default: () => [],
    },
    withOperation: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formData: Object.assign({}, this.cate),
      showCategorySelector: false,
      selectedUsers: [],
    }
  },
  computed: {
    filteredList() {
      return this.parentList.filter(item => item.alias != this.cate.alias)
    },
    currentImages() {
      if (this.formData.relatedMediaPath && this.formData.relatedMediaPath != "") {
        return [this.formData.relatedMediaPath]
      }
      return []
    },
  },
  watch: {
    async cate(newC) {
      const iconMetaData = await queryBusinessTypeIcon(newC.alias)
      this.formData = Object.assign({}, newC, iconMetaData)
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      console.log("fdload")
    },
    doReset() {
      this.formData = Object.assign({}, this.cate)
    },
    async doDelete() {
      this.showCategorySelector = true
    },
    async doUpdate() {
      try {
        if (!this.formData.alias) throw new Error("没有选择分类")
        await updateBusinesstype(this.formData)
        this.$Message.success("更新成功")
        this.$emit("updated")
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async userSelectionChanged(selection) {
      this.formData.userIds = selection
    },
    categorySelected(category) {
      if (category && category.alias) {
        this.$Modal.confirm({
          title: "再次确认",
          content: `删除"${this.cate.name}"并将下面所有商品都移动到"${category.name}"？`,
          onOk: async () => {
            await apiUtil.call(this, async () => {
              if (category.alias == this.cate.alias) {
                throw new Error("不能移动到即将删除的这个分类下")
              }
              await deleteBusinesstype(this.formData.alias, category.alias)
              this.$Message.success("删除成功")
              this.$emit("updated")
              this.showCategorySelector = false
              this.formData = initData()
            })
          },
        })
      }
    },
    removeUser(id) {
      this.formData.userIds = this.formData.userIds.filter(i => i != id)
      this.selectedUsers = this.selectedUsers.filter(u => u.id != id)
    },
    imageUploaded(uploadedImages) {
      if (uploadedImages && uploadedImages.length > 0) {
        this.formData.relatedMediaPath = uploadedImages[0].path
        this.formData.relatedMediaSuffix = uploadedImages[0].suffix
      } else {
        this.formData.relatedMediaId = null
        this.formData.relatedMediaPath = ""
        this.formData.relatedMediaSuffix = ""
      }
    },
    imageRemoved() {
      this.formData.relatedMediaId = null
      this.formData.relatedMediaPath = ""
      this.formData.relatedMediaSuffix = ""
    },
  },
}
</script>

<style></style>
