<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem label="">
        <ButtonGroup>
          <Button type="primary" @click="dialogDisplay = true">添加</Button>
        </ButtonGroup>
      </FormItem>
    </Form>
    <Modal v-model="dialogDisplay" title="添加分类" @on-ok="onNewSubmit">
      <BusinesstypeEditor ref="newEditor" :parentList="businessTypeInList" :withOperation="false" :disabled="false" />
    </Modal>
    <div class="catePanel">
      <Tree class="cateTree" :data="categories" style="width: 350px" @on-select-change="onSelected" />
      <div class="cateEditor">
        <BusinesstypeEditor
          ref="updateEditor"
          :cate="selected"
          :parentList="businessTypeInList"
          :disabled="disableUpdate"
          @updated="onUpdated"
        />
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { createBusinesstype, queryBusinesstype } from "@/api/"
import BusinesstypeEditor from "@/components/businesstype/BusinesstypeEditor.vue"

export default {
  name: "StoreCategoryQueryPage",
  components: { ContentWrapper, BusinesstypeEditor },
  data() {
    return {
      topLinks: [{ title: "业态管理", link: this.$route.path }],
      categories: [],
      businessTypeInList: [],
      selected: {},
      dialogDisplay: false,
      disableUpdate: true
    }
  },
  computed: {},
  watch: {
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      this.businessTypeInList.push({ name: cate.name, alias: cate.alias })
      return cate
    },
    async reload() {
      try {
        this.businessTypeInList = []
        const root = this.process(await queryBusinesstype())
        this.categories = [root]
      } catch (e) {
        this.$Message.error({ background: true, content: e.message })
      }
    },
    onSelected(selectedArray, selected) {
      if (!selectedArray || selectedArray.length === 0) return
      this.selected = {
        alias: selected.alias,
        name: selected.name,
        parentAlias: selected.parentAlias,
        priority: selected.priority,
        icon: selected.icon
      }
      if (this.selected.alias === "root") {
        this.disableUpdate = true
      } else {
        this.disableUpdate = false
      }
    },
    async onUpdated() {
      await this.reload()
    },
    async onNewSubmit() {
      try {
        await createBusinesstype(this.$refs.newEditor.formData)
        this.$Message.success("新建成功")
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>

<style>
.catePanel {
  display: flex;
}
.cateTree {
  background: #eee;
  border-radius: 5px;
}
.cateEditor {
  margin: 8px 0;
  flex: 1;
}
</style>
