<template>
  <div class="catePanel">
    <Tree class="cateTree" :data="categories" style="width: 350px" @on-select-change="onSelected" />
  </div>
</template>

<script>
import { queryBusinesstype } from "@/api/"

export default {
  name: "CategorySelector",
  components: {},
  data() {
    return {
      topLinks: [{ title: "业态管理", link: this.$route.path }],
      categories: [],
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      return cate
    },
    async reload() {
      try {
        const root = this.process(await queryBusinesstype())
        this.categories = [root]
      } catch (e) {
        this.$Message.error({ background: true, content: e.message })
      }
    },
    async onSelected(selectedArray, selected) {
      if (!selectedArray || selectedArray.length === 0) return
      if (selected.alias !== "root") {
        try {
          this.$emit("categorySelected", {
            alias: selected.alias,
            name: selected.name,
            parentAlias: selected.parentAlias,
            icon: selected.icon
          })
        } catch (e) {
          this.$Message.error(e.message)
        }
      }
    },
  },
}
</script>

<style>
.catePanel {
  display: flex;
}
.cateTree {
  background: #eee;
  border-radius: 5px;
}
.cateEditor {
  margin: 8px 0;
  flex: 1;
}
</style>
