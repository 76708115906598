<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Select v-model="selectedVariants" multiple placeholder="过滤显示">
          <Option v-for="item in variants" :value="item.name" :key="item.name">
            {{ item.title }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="">
        <ButtonGroup>
          <Button type="primary" @click="dialogDisplay = true">添加</Button>
        </ButtonGroup>
      </FormItem>
    </Form>
    <Modal v-model="dialogDisplay" title="添加分类" @on-ok="onNewSubmit">
      <CategoryEditor ref="newEditor" :parentList="categoryInList" :withOperation="false" :disabled="false" />
    </Modal>
    <div class="catePanel">
      <Tree class="cateTree" :data="categories" style="width: 350px" @on-select-change="onSelected" />
      <div class="cateEditor">
        <CategoryEditor
          ref="updateEditor"
          :cate="selected"
          :parentList="categoryInList"
          :disabled="disableUpdate"
          @updated="onUpdated"
        />
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import { createCategory, queryCategories, queryVariants } from "@/api/"
import CategoryEditor from "@/components/cate/CategoryEditor.vue"

export default {
  name: "CategoryQueryPage",
  components: { ContentWrapper, CategoryEditor },
  data() {
    return {
      topLinks: [{ title: "分类管理", link: this.$route.path }],
      categories: [],
      categoryInList: [],
      selected: {},
      dialogDisplay: false,
      disableUpdate: true,
      variants: [],
      selectedVariants: [],
    }
  },
  computed: {},
  watch: {
    async selectedVariants() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      this.categoryInList.push({ name: cate.name, alias: cate.alias })
      return cate
    },
    async reload() {
      try {
        this.categoryInList = []
        this.variants = await queryVariants()
        const root = this.process(await queryCategories(this.selectedVariants))
        this.categories = [root]
      } catch (e) {
        this.$Message.error({ background: true, content: e.message })
      }
    },
    onSelected(selectedArray, selected) {
      if (!selectedArray || selectedArray.length === 0) return
      this.selected = {
        alias: selected.alias,
        name: selected.name,
        parentAlias: selected.parentAlias,
        priority: selected.priority,
        icon: selected.icon,
        variants: selected.variants,
        overrideStartAt: selected.overrideStartAt ? new Date(selected.overrideStartAt) : null,
        overrideTTL: selected.overrideStartAt ? selected.overrideTTL : null,
        disableInHome: selected.disableInHome,
        disableInSearch: selected.disableInSearch,
        userIds: selected.userIds,
      }
      if (this.selected.alias === "root") {
        this.disableUpdate = true
      } else {
        this.disableUpdate = false
      }
    },
    async onUpdated() {
      await this.reload()
    },
    async onNewSubmit() {
      try {
        await createCategory(this.$refs.newEditor.formData)
        this.$Message.success("新建成功")
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>

<style>
.catePanel {
  display: flex;
}
.cateTree {
  background: #eee;
  border-radius: 5px;
}
.cateEditor {
  margin: 8px 0;
  flex: 1;
}
</style>
